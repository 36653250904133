<template>
  <div>
    <b-card style="margin-bottom: -1rem">
      <h4 class="">
        <feather-icon
          icon="BoxIcon"
          style="width: 18px; height: 18px"
        />
        조합설정
        <b-badge
          variant="primary"
          style="font-size: xx-small"
        >
          DB 연계
        </b-badge>
      </h4>
      <hr>
      <b-table
        responsive
        show-empty
        small
        striped
        empty-text="No matching records found"
        :items="fetchGameSettCombination2"
        :fields="fields"
        style="text-align: center !important; font-size: small"
      >
        <template #cell(no)="data">
          <div
            class=""
            style="min-width: 1rem"
          >
            {{ data.index + 1 }}
          </div>
        </template>
        <template #cell(type)="data">
          <div
            class=""
            style="min-width: 3rem"
          >
            {{ data.item.type }}
          </div>
        </template>
        <template #cell(menuId)="data">
          <div
            class=""
            style="min-width: 3rem"
          >
            {{ data.item.menuId }}
          </div>
        </template>
        <template #cell(menuName)="data">
          <div
            class=""
            style="min-width: 3rem"
          >
            {{ data.item.menuName }}
          </div>
        </template>
        <template #cell(combinationRule)="data">
          <div
            class="ml-3"
            style="text-align: left; min-width: 10rem"
          >
            {{ data.item.combinationRule }}
          </div>
        </template>
        <template #cell(combinationRuleDesc)="data">
          <div
            class=""
            style="text-align: left; min-width: 10rem"
          >
            {{ data.item.combinationRuleDesc }}
          </div>
        </template>
        <template #cell(combinationMsg)="data">
          <div
            class=""
            style="text-align: left; min-width: 10rem"
          >
            {{ data.item.combinationMsg }}
          </div>
        </template>
      </b-table>
      <b-col
        id="basic1-btn"
        class="mt-1"
        cols="12"
      >
        <b-button
          ref="submit"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="primary"
          variant="primary"
          class="w-auto content-right"
          size="sm"
          @click="clearSelect()"
          v-b-modal.modal-lg
        >
          신규
        </b-button>
      </b-col>
<!--      {{ site }}-->
    </b-card>
    <b-modal
      id="modal-lg"
      ref="my-modal"
      title="신규조합생성"
      centered
      button-size="sm"
      size="lg"
      ok-title="저장"
      @ok="submit('insertCombination')"
    >
      <b-row>
        <b-col cols="6">
          <b-form-group
            class="font-small-3"
            label="메뉴"
            label-for="menu"
          >
            <b-form-select
              id="menu"
              class="font-small-3"
              size="sm"
              style="color:rgb(229,166,48)"
              @change="updateSelected1($event)"
              :options="option1"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            class="font-small-3"
            label="종목"
            label-for="item"
          >
            <b-form-select
              id="item"
              class="font-small-3"
              size="sm"
              style="color:rgb(229,166,48)"
              @change="updateSelected2($event)"
              :options="option2"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            class="font-small-3"
            label="조합1"
            label-for="oddsType1"
          >
            <b-form-select
              id="oddsType1"
              class="font-small-3"
              size="sm"
              style="color:rgb(229,166,48)"
              @change="updateSelected3($event)"
              :options="option3"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            class="font-small-3"
            label="조합2"
            label-for="oddsType2"
          >
            <b-form-select
              id="oddsType2"
              class="font-small-3"
              size="sm"
              style="color:rgb(229,166,48)"
              @change="updateSelected4($event)"
              :options="option4"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            class="font-small-3"
            label="폴드"
            label-for="fold"
          >
            <b-form-select
              id="fold"
              class="font-small-3"
              size="sm"
              style="color:rgb(229,166,48)"
              @change="updateSelected5($event)"
              :options="option5"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col>
          <div>
            메뉴/종목 : {{ selected1.text }} / {{ selected2.text }}
          </div>
          <div>
            조합불가로직 : {{ selected3.value }} + {{ selected4.value }} <span v-if="selected5.value != 0">+ {{ selected5.value }}</span>
          </div>
          <div>
            조합불가규칙 : {{ selected3.text }} + {{ selected4.text }}
          </div>
          <div v-if="selected5.value === 0 || selected5.value === undefined">
            조합불가 메세지 : {{ selected1.text }} {{ selected2.text }}종목의 {{ selected3.text }} + {{ selected4.text }} 조합이 불가능합니다.
          </div>
          <div v-else>
            조합불가 메세지 : {{ selected1.text }} {{ selected2.text }} 종목의 {{ selected3.text }} + {{ selected4.text }}<span v-if="selected5.value != 0"> , {{ selected5.text }} 폴드이하</span> 조합이 불가능합니다.
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BFormInput,
  BButton,
  BBadge,
  BTable,
  BCol,
  BRow,
  BFormSelect,
  BFormGroup,
  BFormSelectOption,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'
import { createNamespacedHelpers } from 'vuex'

import { FETCH_GAME_SET_MENU, FETCH_GAME_SET_ITEM, FETCH_GAME_SET_ODDS_CUSTOM, FETCH_GAME_SET_COMBINATION } from '@/store/game/action'
import { UPDATE_GAME_SET_COMBINATION } from '@/store/game/mutation'

const gameStore = createNamespacedHelpers('gameStore')

export default {
  name: 'GameSettingsTabCombination',
  components: {
    BCard,
    BFormInput,
    BButton,
    BBadge,
    BTable,
    BRow,
    BCol,
    BFormSelect,
    BFormGroup,
    BFormSelectOption,
  },
  directives: {
    Ripple,
  },
  props: {
    site: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      option1: [],
      option2: [],
      option3: [],
      option4: [],
      option5: [
        { value: '', text: '선택' },
        { value: 1, text: '1' },
        { value: 2, text: '2' },
        { value: 3, text: '3' },
        { value: 4, text: '4' },
        { value: 5, text: '5' },
      ],
      insertData: {
        site: this.site,
        menu: null,
        item: null,
        oddsType1: null,
        oddsType2: null,
        fold: null,
      },
      selected1: { value: '', text: '선택' },
      selected2: { value: '', text: '선택' },
      selected3: { value: '', text: '선택' },
      selected4: { value: '', text: '선택' },
      selected5: { value: '', text: '선택' },
      fetchGameSettCombination2: [
        {
          no: 1,
          type: 'sports',
          menuId: '01',
          menuName: 'prematch',
          itemId: '001',
          itemName: 'soccer',
          combinationRule: 'Match Winner + Asian Handicap',
          combinationRuleDesc: '승무패 + 핸디캡',
          combinationMsg: '축구 승무패, 핸디캡은 조합이 불가능합니다.',
        },
        {
          no: 2,
          type: 'sports',
          menuId: '01',
          menuName: 'prematch',
          itemId: '001',
          itemName: 'soccer',
          combinationRule: 'Match Winner + Goals Over/Under',
          combinationRuleDesc: '승무패 + 오버언더',
          combinationMsg: '축구 승무패, 오버언더는 조합이 불가능합니다.',
        },
        {
          no: 3,
          type: 'sports',
          menuId: '01',
          menuName: 'prematch',
          itemId: '001',
          itemName: 'soccer',
          combinationRule: 'Asian Handicap + Goals Over/Under',
          combinationRuleDesc: '핸디캡 + 오버언더',
          combinationMsg: '축구 핸디캡, 오버언더는 조합이 불가능합니다.',
        },
      ],
      fields: [
        {
          key: 'no', sortable: false, label: 'No.',
        },
        {
          key: 'type', sortable: false, label: '타입',
        },
        {
          key: 'menuId', sortable: false, label: 'Code',
        },
        {
          key: 'menuName', sortable: false, label: '메뉴명',
        },
        {
          key: 'itemId', sortable: false, label: 'Code',
        },
        {
          key: 'itemName', sortable: false, label: '종목명',
        },
        {
          key: 'combinationRule', sortable: false, label: '조합불가 로직',
        },
        {
          key: 'combinationRuleDesc', sortable: false, label: '조합불가 규칙',
        },
        {
          key: 'combinationMsg', sortable: false, label: '조합불가 메세지',
        },
      ],
    }
  },
  computed: {
    ...gameStore.mapGetters({
      fetchGameSetMenu: 'fetchGameSetMenu',
      fetchGameSetItem: 'fetchGameSetItem',
      fetchGameSetOddsCustom1: 'fetchGameSetOddsCustom',
      fetchGameSetOddsCustom2: 'fetchGameSetOddsCustom',
      fetchGameSettCombination: 'fetchGameSettCombination',
      updateGameSettCombination: 'updateGameSettCombination',
    }),
  },
  created() {
  },
  watch: {
    site: {
      handler(event) {
        this.fetchData()
        // localStorage.setItem('authSiteSelected', event)
        // this.$store.commit('footerStore/setSelectedSiteIndex', this.authSite.indexOf(event))
      },
      immediate: false,
      deep: false,
    },
    selected1() {
      this.updateOption2()
      this.updateOption3()
      this.updateOption4()
    },
    selected2() {
      this.updateOption3()
      this.updateOption4()
    },
    selected3() {
      this.updateOption4()
    },
  },
  mounted() {
    // this.fetchData()
  },
  setup() {
  },
  methods: {
    ...gameStore.mapActions({
      $fetchGameSetMenu: FETCH_GAME_SET_MENU,
      $fetchGameSetItem: FETCH_GAME_SET_ITEM,
      $fetchGameSetOddsCustom1: FETCH_GAME_SET_ODDS_CUSTOM,
      $fetchGameSetOddsCustom2: FETCH_GAME_SET_ODDS_CUSTOM,
      $fetchGameSettCombination: FETCH_GAME_SET_COMBINATION,
      $updateGameSettCombination: UPDATE_GAME_SET_COMBINATION,
    }),
    async fetchData() {
      const mySite = this.site
      await this.$fetchGameSetMenu({
        site: mySite,
      })
      await this.$fetchGameSetItem({
        site: mySite,
      })
      await this.$fetchGameSetOddsCustom1({
        site: mySite,
      })
      await this.$fetchGameSetOddsCustom2({
        site: mySite,
      })
      await this.$fetchGameSettCombination({
        site: mySite,
      })
      // console.log(this.fetchGameSetMenu)
      // console.log(this.fetchGameSetMenu)
      // console.log(this.fetchGameSetItem)
      // console.log(this.fetchGameSetOddsCustom)
      // console.log(this.fetchGameSettCombination)

      this.updateOption1()
    },
    async submit(target) {
      await Swal.fire({
        title: '변경내용을 저장하시겠습니까?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.confirmYes(target)
        }
      })
    },
    // confirm Yes
    async confirmYes(target) {
      const mySite = this.site
      try {
        if (target === 'updateGameSettCombination') {
          await this.$updateGameSettCombination({
            site: mySite,
            // store의 updated 된 param을 사용한다.
          })
        }
      } catch {
        console.error()
      } finally {
        Swal.fire({
          title: '저장 완료',
          icon: 'success',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then(result => {
          this.fetchData()
        })
      }
    },
    clearSelect() {
    //   console.log(this.selected2)
    //   this.selected1.text = '선택'
    //   this.selected2 = { value: '', text: '선택' }
    //   this.selected3 = { value: '', text: '선택' }
    //   this.selected4 = { value: '', text: '선택' }
    //   this.selected5 = { value: '', text: '선택' }
    },
    updateOption1() {
      this.option1 = this.fetchGameSetMenu
        .filter(item => item.useYn === 'Y' && item.type === 'sports')
        .map(item => ({
          value: item.name,
          text: item.nameCustom,
        }))

      // 배열의 시작 부분에 "선택" 옵션 추가
      this.option1.unshift({ value: '', text: '선택' })
    },
    updateOption2() {
      console.log(this.selected1.value)
      this.option2 = this.fetchGameSetItem
        .filter(item => item.useYn === 'Y' && item.type === 'sports' && item.menuName === this.selected1.value)
        .map(item => ({
          value: item.name,
          text: item.nameCustom,
        }))

      // 배열의 시작 부분에 "선택" 옵션 추가
      this.option2.unshift({ value: '', text: '선택' })
    },
    updateOption3() {
      console.log('this.selected2.value ::', this.selected2.value)
      this.option3 = this.fetchGameSetOddsCustom1
        .filter(item => item.type === 'sports' && item.menuName === this.selected1.value && item.itemName === this.selected2.value)
        .map(item => ({
          value: item.oddsType,
          text: item.oddsTypeName,
        }))

      // 배열의 시작 부분에 "선택" 옵션 추가
      this.option3.unshift({ value: '', text: '선택' })
    },
    updateOption4() {
      console.log('this.fetchGameSetOddsCustom2 ::', this.fetchGameSetOddsCustom2)
      this.option4 = this.fetchGameSetOddsCustom2
        .filter(item => item.type === 'sports' && item.menuName === this.selected1.value && item.itemName === this.selected2.value && item.oddsType !== this.selected3.value)
        .map(item => ({
          value: item.oddsType,
          text: item.oddsTypeName,
        }))

      // 배열의 시작 부분에 "선택" 옵션 추가
      this.option4.unshift({ value: '', text: '선택' })
    },
    updateSelected1(selectedValue) {
      const selectedOption = this.option1.find(option => option.value === selectedValue)
      if (selectedOption) {
        this.selected1 = {
          value: selectedOption.value,
          text: selectedOption.text,
        }
      }
    },
    updateSelected2(selectedValue) {
      const selectedOption = this.option2.find(option => option.value === selectedValue)
      if (selectedOption) {
        this.selected2 = {
          value: selectedOption.value,
          text: selectedOption.text,
        }
      }
    },
    updateSelected3(selectedValue) {
      const selectedOption = this.option3.find(option => option.value === selectedValue)
      if (selectedOption) {
        this.selected3 = {
          value: selectedOption.value,
          text: selectedOption.text,
        }
      }
    },
    updateSelected4(selectedValue) {
      const selectedOption = this.option4.find(option => option.value === selectedValue)
      if (selectedOption) {
        this.selected4 = {
          value: selectedOption.value,
          text: selectedOption.text,
        }
      }
    },
    updateSelected5(selectedValue) {
      const selectedOption = this.option5.find(option => option.value === selectedValue)
      if (selectedOption) {
        this.selected5 = {
          value: selectedOption.value,
          text: selectedOption.text,
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
