<template>
  <div>
    <b-card style="margin-bottom: -1rem">
      <h4 class="">
        <feather-icon
          icon="BoxIcon"
          style="width: 18px; height: 18px"
        />
        게임 리그관리
        <b-badge
          variant="light-primary"
          style="font-size: small; margin-left: 5px; margin-right: 3px"
          class="badge-glow"
        >
          {{ totalRows }}건
        </b-badge>
        <b-badge
          variant="primary"
          style="font-size: xx-small"
        >
          DB 연계
        </b-badge>
      </h4>
      <hr>
      <div class="m-1">
        <!-- Per Page -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="3" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <b-form-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              size="sm"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>
          <b-col cols="9" md="6" sm="12" class="mb-sm-1 d-flex justify-content-between align-items-center">
            <div class="d-inline-flex">
              <b-form-group class="mb-0">
                <b-form-checkbox v-model="filterByNotUse" inline @change="fetchData">
                  <div class="font-small-3">미사용 리그만 보기</div>
                </b-form-checkbox>
              </b-form-group>
              <b-form-group class="mb-0">
                <b-form-checkbox v-model="filterByNotExpired" inline @change="fetchData">
                  <div class="font-small-3">미종료 리그만 보기</div>
                </b-form-checkbox>
              </b-form-group>
            </div>
<!--            <div-->
<!--              class="editClass ml-5"-->
<!--              @click.stop-->
<!--            >-->
<!--              <span>-->
<!--                <b-button-->
<!--                  variant="primary"-->
<!--                  class="mr-1"-->
<!--                  size="sm"-->
<!--                  v-if="!complete"-->
<!--                >-->
<!--                  <b-spinner small/>-->
<!--                  팀정보 생성중...-->
<!--                </b-button>-->
<!--                <b-button-->
<!--                  v-if="complete"-->
<!--                  ref="submitCreate"-->
<!--                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
<!--                  type="submit"-->
<!--                  variant="success"-->
<!--                  class="mr-1"-->
<!--                  style="float: right"-->
<!--                  size="sm"-->
<!--                  @click="submitCreate('createGameSetTeam')"-->
<!--                >-->
<!--                  팀정보 생성-->
<!--                </b-button>-->
<!--              </span>-->
<!--            </div>-->
          </b-col>
          <b-col cols="12" md="6">
            <b-button size="sm" class="" style="margin-right: 5px" :variant="selectedButton === '1' ? 'primary' : 'outline-secondary'" @click="menuSelect('soccer', '1')">축구</b-button>
            <b-button size="sm" class="" style="margin-right: 5px" :variant="selectedButton === '2' ? 'primary' : 'outline-secondary'" @click="menuSelect('basketball', '2')">농구</b-button>
            <b-button size="sm" class="" style="margin-right: 5px" :variant="selectedButton === '3' ? 'primary' : 'outline-secondary'" @click="menuSelect('baseball', '3')">야구</b-button>
            <b-button size="sm" class="" style="margin-right: 5px" :variant="selectedButton === '4' ? 'primary' : 'outline-secondary'" @click="menuSelect('tennis', '4')">테니스</b-button>
            <b-button size="sm" class="" style="margin-right: 5px" :variant="selectedButton === '5' ? 'primary' : 'outline-secondary'" @click="menuSelect('volleyball', '5')">배구</b-button>
            <b-button size="sm" class="" style="margin-right: 5px" :variant="selectedButton === '6' ? 'primary' : 'outline-secondary'" @click="menuSelect('football', '6')">풋볼</b-button>
            <b-button size="sm" class="" style="margin-right: 5px" :variant="selectedButton === '7' ? 'primary' : 'outline-secondary'" @click="menuSelect('hockey', '7')">하키</b-button>
            <b-button size="sm" class="" style="margin-right: 5px" :variant="selectedButton === '8' ? 'primary' : 'outline-secondary'" @click="menuSelect('esports', '8')">E스포츠</b-button>
          </b-col>
          <!-- Search -->
          <b-col cols="12" md="6">
            <b-form-group class="mb-0">
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <!-- Table -->
      <b-table
        responsive
        show-empty
        hover
        small
        empty-text="No matching records found"
        :per-page="perPage"
        :items="filteredLeagues"
        :fields="fields"
        :filter="filter"
        :filter-included-fields="filterOn"
        style="text-align: center !important; font-size: small"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :current-page="currentPage"
        @filtered="onFiltered"
        @row-clicked="rowClickHandler"
      >
        <template #cell(no)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(leagueId)="data">
          <div
            style="min-width: 5rem"
          >
            {{ data.item.leagueId }}
          </div>
        </template>
        <template #cell(country)="data">
          <div style="text-align: left; width: 14rem;">
            <img
              class="league-logo-img"
              style="width: 28px; margin-right: 5px;"
              :src="`https://agress-assets.s3.ap-northeast-2.amazonaws.com/img_league_full_name_svg/${data.item.country}.svg`"
              @error="handleImageError"
            >
            <span
              style="text-align: left"
            >
              {{ data.item.country }}
            </span>
          </div>
        </template>
        <template #cell(leagueName)="data">
          <div
            style="text-align: left"
          >
            {{ data.item.leagueName }}
          </div>
        </template>
        <template #cell(leagueNameCustom)="data">
          <b-form-input
            :value="data.item.leagueNameCustom"
            style="text-align: left; min-width: 7rem; height: 2rem; color:rgb(229,166,48); font-size: small"
            @change="updateParam('updateGameSetLeague' , 'fetchGameSetLeague' ,'leagueNameCustom', $event, data.item)"
          >
          </b-form-input>
        </template>
        <template #cell(useYn)="data">
          <b-form-select
            id="useYn"
            :value="data.item.useYn"
            :style="{ color: data.item.useYn === 'Y' ? 'rgb(229,166,48)' : 'grey', width: '5rem', textAlign: 'center' }"
            size="sm"
            class="center"
            :options="options1"
            @change="updateParam('updateGameSetLeague','fetchGameSetLeague' ,'useYn', $event, data.item)"
          />
        </template>
        <template #cell(iscup)="data">
          <div v-if="data.item.iscup === 'True'" style="color: chartreuse">
            ✔
          </div>
        </template>
        <template #cell(is_women)="data">
          <div v-if="data.item.is_women === 'True'" style="color: chartreuse">
            ✔
          </div>
        </template>
        <template #cell(date_end)="data">
          <div v-if="todayChk(data.item.date_end)">
            <div style="color: rgba(106,99,99,0.61)">
              {{ data.item.date_end }}
            </div>
          </div>
          <div v-else>
            {{ data.item.date_end }}
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="12"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
      <!-- submit and reset -->
      <b-col
        id="save-btn"
        class="mt-1"
        cols="12"
      >
        <b-button
          ref="submit"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class=""
          style="float: right"
          size="sm"
          @click="submit('updateGameSetLeague')"
        >
          저장
        </b-button>
      </b-col>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BBadge,
  BPagination,
  BFormGroup,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'
import { createNamespacedHelpers } from 'vuex'

import { FETCH_GAME_SET_LEAGUE } from '@/store/game/action'
import { UPDATE_GAME_SET_LEAGUE, CREATE_GAME_SET_TEAM, UPDATE_GAME_SET_TEAM_CUSTOM_NAME } from '@/store/game/mutation'

import * as moment from 'moment-timezone'
moment().tz('Asia/Seoul')
const fmt1 = 'YYYY-MM-DD HH:mm'
const fmt2 = 'YYYYMMDD'

const gameStore = createNamespacedHelpers('gameStore')

export default {
  name: 'GameSettingsTabLeague',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BTable,
    BPagination,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BBadge,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  props: {
    site: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      selectSports: 'soccer',
      selectedButton: '1',
      complete: true,
      options1: [
        { value: 'N', text: 'N' },
        { value: 'Y', text: 'Y' },
      ],
      connectedUsers: [],
      socket: null,
      perPage: 100,
      pageOptions: [50, 100, 500],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      currentPage: 1,
      totalRows: 1,
      fields: [
        { key: 'no', sortable: false, label: 'No.' },
        { key: 'sports', sortable: false, label: '종목명' },
        { key: 'leagueId', sortable: false, label: '리그코드' },
        { key: 'country', sortable: false, label: '국가' },
        { key: 'leagueName', sortable: false, label: '리그명' },
        { key: 'leagueNameCustom', sortable: false, label: '리그명(변경)' },
        { key: 'season', sortable: false, label: '시즌' },
        { key: 'date_start', sortable: false, label: '시즌 시작' },
        { key: 'date_end', sortable: false, label: '시즌 종료' },
        { key: 'iscup', sortable: false, label: '컵' },
        // { key: 'live_stats', sortable: false, label: '스텟' },
        // { key: 'live_lineups', sortable: false, label: '라인업' },
        // { key: 'path', sortable: false, label: '경로' },
        { key: 'is_women', sortable: false, label: '여성' },
        { key: 'useYn', sortable: false, label: '사용여부' },
      ],
      filterByNotUse: false,
      filterByNotExpired: true,
      filterByCup: false,
    }
  },
  computed: {
    ...gameStore.mapGetters({
      fetchGameSetLeague: 'fetchGameSetLeague',
      updateGameSetLeague: 'updateGameSetLeague',
      createGameSetTeam: 'createGameSetTeam',
    }),
    filteredLeagues() {
      let filteredItems = this.fetchGameSetLeague

      // 기본 텍스트 검색
      if (this.searchFilter) {
        filteredItems = filteredItems.filter(item => Object.values(item).some(val => val && val.toString().toLowerCase().includes(this.searchFilter.toLowerCase())))
      }
      // // '미사용 리그만 보기'가 활성화된 경우 useYn이 'N'인 항목만 필터링
      // if (this.filterByNotUse) {
      //   // filteredItems = filteredItems.filter(item => item.useYn === 'N')
      //   this.fetchData()
      // }
      // // '미종료 리그만 보기'가 활성화된 경우 리그 종료일이 당일 이후 항목만 필터링
      // if (this.filterByNotExpired) {
      //   filteredItems = filteredItems.filter(item => this.todayChk(item.date_end) === false)
      // }
      // // '컵리그만 보기'가 활성화된 경우 컵 리그 항목만 필터링
      // if (this.filterByCup) {
      //   filteredItems = filteredItems.filter(item => item.iscup === 'True')
      // }

      this.totalRows = filteredItems.length
      return filteredItems
    },
  },
  created() {
  },
  watch: {
    site: {
      handler(event) {
        this.fetchData()
        // localStorage.setItem('authSiteSelected', event)
        // this.$store.commit('footerStore/setSelectedSiteIndex', this.authSite.indexOf(event))
      },
      immediate: false,
      deep: false,
    },
  },
  mounted() {
    // this.fetchData()
  },
  methods: {
    ...gameStore.mapActions({
      $fetchGameSetLeague: FETCH_GAME_SET_LEAGUE,
      $updateGameSetLeague: UPDATE_GAME_SET_LEAGUE,
      $createGameSetTeam: CREATE_GAME_SET_TEAM,
    }),
    updateParam(t, f, e, v, i) {
      // console.log(t)
      // console.log(f)
      // console.log(e)
      // console.log(v)
      // console.log(i)
      const events = {
        t, f, e, v, i,
      }
      // store에 param을 update 한다
      this.$store.commit('gameStore/updateManyLeagueParam', events)
    },
    async menuSelect(sports, index) {
      this.selectSports = sports
      this.selectedButton = index
      await this.fetchData()
      console.log('selectedSports ::', this.selectSports)
    },
    async fetchData() {
      const mySite = this.site
      let notExpired = true
      if (this.selectSports !== 'soccer') {
        notExpired = false
      } else {
        notExpired = this.filterByNotExpired
      }
      await this.$fetchGameSetLeague({
        site: mySite,
        useYn: this.filterByNotUse,
        date_end: notExpired,
        sports: this.selectSports,
      })
      this.totalRows = this.fetchGameSetLeague.length
    },
    async submit(target, data) {
      await Swal.fire({
        title: '변경 내용을 저장 하시겠습니까?',
        // text: Number(this.desiredBet).toLocaleString(),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.confirmYes(target, data)
        }
      })
    },
    async submitCreate(target, data) {
      await Swal.fire({
        title: '팀 정보를 생성 하시겠습니까?',
        // text: '팀 정보는 설정된 리그 기반으로 생성됩니다. 리그의 사용 여부를 설정하시기 바랍니다. 팀 정보 생성에는 사용 리그에 따라 최대 30분 소요됩니다',
        html: "<h5 style='color:rgb(78,238,62)'>" + "팀 정보는 설정된 리그 기반으로 생성됩니다. 리그의 사용 여부를 먼저 설정하시기 바랍니다. 팀 정보 생성에는 사용 리그에 따라 최대 30분 소요됩니다. 다중리그에 속한 팀은 1개 리그에만 소속되어 생성됩니다." + "</h5>",
        icon: 'success',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
          text: 'swal-text-custom',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.confirmYes(target, data)
        }
      })
    },
    // confirm Yes
    async confirmYes(target) {
      const mySite = this.site
      try {
        if (target === 'updateGameSetLeague') {
          await this.$updateGameSetLeague({
            site: mySite,
          })
          // console.log(this.updateGameSetLeague)
        }
        if (target === 'createGameSetTeam') {
          this.complete = false
          await this.$createGameSetTeam({
            site: mySite,
            sports: this.selectSports,
          })
          console.log('createGameSetTeam :: ', this.createGameSetTeam)
        }
      } catch {
        console.error()
      } finally {
        if (target === 'createGameSetTeam') {
          Swal.fire({
            title: '팀정보가 생성이 완료되었습니다',
            html: "<h5 style='color:rgb(78,238,62)'>" + this.createGameSetTeam + '건 생성' + "</h5>",
            icon: 'success',
            timer: 5000,
            background: 'rgb(3,11,37)',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          }).then(async result => {
            await this.fetchData()
            this.complete = true
          })
        } else {
          Swal.fire({
            title: '저장 완료',
            icon: 'success',
            timer: 5000,
            background: 'rgb(3,11,37)',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          }).then(async result => {
            await this.fetchData()
          })
        }
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    rowClickHandler(record) {
    },
    handleImageError(e) {
      e.target.src = 'https://agress-assets.s3.ap-northeast-2.amazonaws.com/img_league_full_name_svg/default.svg'
    },
    todayChk(dateChk) {
      const today = moment().format('YYYY.MM.DD')
      const isToday = moment(dateChk).format('YYYY.MM.DD')

      return today > isToday
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
